import { castArray, compact, includes, map, uniq } from 'lodash';
import { FormControl } from 'native-base';
import { JSX } from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { Chip, Layout, Text } from '../core';
import { FormErrorInput } from './FormErrorInput';
import { FormHelperText } from './FormHelperText';
import { FormLabel } from './FormLabel';
import { FormScrollingProps } from './hooks/useFormScrolling';

type FormChipsProps<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> &
  FormScrollingProps<TFieldValues> & {
    ['aria-describedby']?: string;
    direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
    error?: FieldErrors<TFieldValues>[string];
    helperText?: string;
    hideLabel?: boolean;
    isMulti?: boolean;
    isDisabled?: boolean;
    isRequired?: boolean;
    label?: string;
    necessityIndicator?: boolean;
    onSubmitEditing?: () => void;
    options: Record<string, string>;
    space?: number;
  };

export function FormChips<TFieldValues extends FieldValues>({
  'aria-describedby': ariaDescribedBy,
  direction = 'column',
  control,
  error,
  label = '',
  isDisabled = false,
  isMulti = false,
  isRequired = false,
  name,
  necessityIndicator = false,
  onLayout,
  onSubmitEditing,
  options,
  helperText = '',
  hideLabel = false,
  space = 2,
}: FormChipsProps<TFieldValues>): JSX.Element {
  const finalRules = {
    required: {
      value: Boolean(isRequired),
      message: 'This field is required.',
    },
  };

  const nativeId = name;

  const ariaLabelProps =
    label && hideLabel ? { accessibilityLabel: label } : { 'aria-labelledby': `${nativeId}-label` };
  const ariaDescribedByProps =
    [
      isMulti ? `${nativeId}-multi` : undefined,
      helperText ? `${nativeId}-helptext` : undefined,
      error ? `${nativeId}-feedback` : undefined,
    ]
      .concat((ariaDescribedBy ?? '').split(' '))
      .filter(Boolean)
      .join(' ') || undefined;

  return (
    <FormControl
      {...ariaLabelProps}
      aria-describedby={ariaDescribedByProps}
      aria-required={isRequired}
      role={isMulti ? 'group' : 'radiogroup'}
      isInvalid={Boolean(error?.type)}
      nativeID={nativeId}
      onLayout={onLayout}
    >
      {!hideLabel && (
        <FormLabel
          inputId={nativeId}
          label={label}
          necessityIndicator={necessityIndicator}
          isRequired={isRequired}
        />
      )}

      {isMulti && (
        <Text.paraSmall id={`${nativeId}-multi`} marginBottom={4}>
          Select all that apply
        </Text.paraSmall>
      )}

      <Controller
        name={name}
        control={control}
        rules={finalRules}
        render={({ field: { onChange, value } }) => {
          const currentValues: string[] = castArray(value);

          const onOptionPress = (optionValue: string) => () => {
            if (isMulti) {
              let newValues: string[];

              if (includes(currentValues, optionValue)) {
                newValues = currentValues.filter(currentValue => currentValue !== optionValue);
              } else {
                newValues = uniq([...currentValues, optionValue]);
              }

              onChange(compact(newValues));
              onSubmitEditing?.();
              return;
            }

            if (isRequired) {
              onChange(optionValue);
              onSubmitEditing?.();
              return;
            }

            onChange(value === optionValue ? '' : optionValue);
            onSubmitEditing?.();
          };

          return (
            <Layout.Stack direction={direction} space={space}>
              {map(options, (optionLabel, optionValue) => {
                return (
                  <Chip.primarySmall
                    key={optionValue}
                    isDisabled={isDisabled}
                    isMulti={isMulti}
                    isSelected={includes(currentValues, optionValue)}
                    testID={`chip-form-chips-${optionValue}`}
                    onPress={onOptionPress(optionValue)}
                  >
                    {optionLabel}
                  </Chip.primarySmall>
                );
              })}
            </Layout.Stack>
          );
        }}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {error && <FormErrorInput nativeID={nativeId}>{error.message}</FormErrorInput>}
    </FormControl>
  );
}
