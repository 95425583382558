import { ArrowBackIcon, CloseIcon, KeyboardAvoidingView, PresenceTransition } from 'native-base';
import { ReactNode } from 'react';
import { Platform } from 'react-native';
import { getStylesheet } from '../../styles';
import { Button, Heading, Layout, Progress } from '../core';
import { SlideContextType } from './slideContextFactory';
import { ISlide } from './SlideTypes';

type SlidesWrapperProps<MoreSlideProps> = {
  context: SlideContextType<ISlide<MoreSlideProps>>;
  children: ReactNode;
  onClose: () => void;
  title?: string;
  progressOffsetStart?: number;
  progressOffsetEnd?: number;
};

export const SlidesWrapper = <MoreSlideProps,>({
  context,
  children,
  onClose,
  title = '',
  progressOffsetStart = 0,
  progressOffsetEnd = 0,
}: SlidesWrapperProps<MoreSlideProps>): JSX.Element => {
  const { slideCount, slideNumber, triggerPrevSlide } = context;

  const hasStarted = slideNumber > 1;
  const preventBack = slideNumber > slideCount - progressOffsetEnd;

  const offsetSlideNumber = slideNumber - 1 - progressOffsetStart;
  const offsetSlideCount = slideCount - 1 - progressOffsetStart - progressOffsetEnd;
  const progressPercent = Math.round((offsetSlideNumber * 100) / offsetSlideCount) || 0;

  const onBackPressed = (): void => {
    if (!hasStarted) {
      onClose();
      return;
    }

    triggerPrevSlide();
  };

  const animation = {
    initial: {
      translateY: -200,
    },
    animate: {
      translateY: 0,
      transition: {
        duration: 250,
      },
    },
  };

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : undefined} flex={1}>
      <Layout.View {...styles.base} testID="slide-wrapper">
        <Layout.HStack {...styles.header} space={0}>
          <PresenceTransition visible={hasStarted} {...animation}>
            {!preventBack ? (
              <Button.tertiaryMedium
                accessibilityLabel="Show the previous slide."
                leftIcon={<ArrowBackIcon accessibilityHidden />}
                testID="button-skill-back"
                onPress={onBackPressed}
              />
            ) : (
              <Button.tertiaryMedium
                accessibilityLabel="Show the previous slide."
                leftIcon={<ArrowBackIcon accessibilityHidden />}
                testID="button-skill-back"
                opacity={0}
                disabled
              />
            )}
          </PresenceTransition>

          {title && <Heading.h5 {...styles.title}>{title}</Heading.h5>}

          <Button.tertiaryMedium
            accessibilityLabel="Close."
            rightIcon={<CloseIcon accessibilityHidden />}
            testID="button-slides-close"
            onPress={onClose}
          />
        </Layout.HStack>

        <PresenceTransition visible={progressPercent >= 0 && progressPercent <= 100} {...animation}>
          <Layout.Center position="absolute" left={0} right={0} bottom={3}>
            <Progress
              accessibilityLabel="Slide progress"
              variant="skill"
              width="225px"
              aria-valuetext={String(offsetSlideNumber)}
              aria-valuemax={offsetSlideCount}
              aria-valuemin={1}
              value={progressPercent}
            />
          </Layout.Center>
        </PresenceTransition>

        {children}
      </Layout.View>
    </KeyboardAvoidingView>
  );
};

const styles = getStylesheet({
  base: {
    flex: 1,
  },

  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 2,
    paddingBottom: 6,
    paddingX: 1,
  },

  title: {
    textAlign: 'center',
  },
});
