import { Button, Heading, Image, Layout, Text } from '../../../components/core';
import { useExternalNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';

type TogetherAllProps = {
  orgName: string;
};

export const TogetherAll = ({ orgName }: TogetherAllProps): JSX.Element => {
  const externalNavigate = useExternalNavigate();

  const onTogetherAllClick = (): void => {
    externalNavigate('https://account.v2.togetherall.com/register/student');
  };

  return (
    <Layout.Shift
      {...styles.togetherAllBox}
      aria-labelledby="togetherall-card-header"
      role="region"
    >
      <Layout.VStack {...styles.togetherAllTopStack} space={4}>
        <Layout.VStack space={4}>
          <Heading.h2 id="togetherall-card-header">Online peer support</Heading.h2>

          <Text.paraSmall>
            {`Connect with other college students in a safe, anonymous environment. Togetherall is available for free for students at ${orgName}.`}
          </Text.paraSmall>
        </Layout.VStack>

        <Button.primaryMedium
          {...styles.togetherButton}
          testID="button-home-go-to-togetherall"
          onPress={onTogetherAllClick}
        >
          Visit Togetherall
        </Button.primaryMedium>
      </Layout.VStack>
      <Layout.VStack {...styles.togetherAllLogos} space={4}>
        <Image
          alt="Togetherall logo."
          height={52}
          width={137}
          source={{
            uri: 'https://images.prismic.io/mantra-hub/0f779d20-cc0a-46ef-961d-ef093cfe4fff_Togetherall_Logo_Primary_Horizontal_RGB.png?auto=compress,format',
          }}
        />
        <Image
          alt="A group of people."
          aria-hidden
          width="100%"
          height={153}
          source={{
            uri: 'https://images.prismic.io/mantra-hub/e5787c21-6aaf-4e4b-8fd7-322a03c14e56_shutterstock_2123132894+%5BConverted%5D+1+%281%29.png?auto=compress,format',
          }}
        />
      </Layout.VStack>
    </Layout.Shift>
  );
};

const styles = getStylesheet({
  togetherAllBox: {
    backgroundColor: 'white',
    borderColor: 'secondary.200',
    borderRadius: 8,
    borderWidth: 1,
    gap: 6,
    justifyContent: 'space-between',
    overflow: 'hidden',
    paddingBottom: 4,
    paddingTop: 12,
    paddingX: 4,
  },

  togetherButton: {
    alignSelf: 'flex-start',
  },

  togetherAllTopStack: {
    flex: 1,
  },

  togetherAllLogos: {
    alignItems: 'center',
    flex: 1,
  },
});
