import { getYear } from 'date-fns';
import { StyledProps } from 'native-base';
import {
  LINK_TO_HEALTH_TERMS,
  LINK_TO_PRIVACY_POLICY,
  LINK_TO_TELEHEALTH_CONSENT,
} from '../../constants/links';
import { useBreakpointSwitch } from '../../contexts/breakpointContext';
import { getStylesheet } from '../../styles';
import { Layout, Link, Text } from '../core';
import { IconMantraLogo } from '../icons/IconMantraLogo';

type FooterProps = StyledProps;

export const PageFooter = ({ ...styleProps }: FooterProps): JSX.Element => {
  // Make sure the footer is fully visible even when the layout changes.
  const paddingBottom = useBreakpointSwitch({
    mobile: 0,
    tablet: 8,
    desktop: 6,
  });

  const currentYear = getYear(new Date());

  return (
    <Layout.VStack {...styles.footer} {...styleProps}>
      <Layout.VStack
        {...styles.emergencyBox}
        accessibilityLabel="Emergency and crisis help"
        role="complementary"
        space={5}
      >
        <Text.paraSmall>
          If you’re in an emergency or crisis and need immediate help, call 988 or go to the nearest
          emergency room.
        </Text.paraSmall>

        <Layout.VStack space={2} alignItems="center">
          <Text.paraSmall>
            <Text.paraSmall bold>Suicide Prevention Hotline:</Text.paraSmall> Call 988
          </Text.paraSmall>

          <Text.paraSmall>
            <Text.paraSmall bold>Crisis Text Line:</Text.paraSmall> Text HOME to 741741
          </Text.paraSmall>
        </Layout.VStack>
      </Layout.VStack>

      <Layout.VStack {...styles.bottom} paddingBottom={paddingBottom} role="contentinfo" space={5}>
        <IconMantraLogo size={31} />

        <Text.paraSmall>
          Questions? Email us at{' '}
          <Link.paraSmall to="mailto:hi@mantrahealth.com">hi@mantrahealth.com</Link.paraSmall>
        </Text.paraSmall>

        <Link.paraSmall bold to={LINK_TO_HEALTH_TERMS}>
          Mantra Health Terms & Conditions
        </Link.paraSmall>
        <Link.paraSmall bold to={LINK_TO_PRIVACY_POLICY}>
          Privacy Policy
        </Link.paraSmall>
        <Link.paraSmall bold to={LINK_TO_TELEHEALTH_CONSENT}>
          Telehealth Consent
        </Link.paraSmall>

        <Text.paraSmall>© {currentYear} Mantra Health</Text.paraSmall>
      </Layout.VStack>
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  footer: {
    alignSelf: 'center',
    marginTop: 16,
    paddingX: 4,
    maxWidth: '450px',
  },

  emergencyBox: {
    borderBottomWidth: 1,
    borderBottomColor: 'primary.300',
    borderTopWidth: 1,
    borderTopColor: 'primary.300',
    paddingY: '52px',
    textAlign: 'center',
  },

  bottom: {
    alignItems: 'center',
    paddingTop: '52px',
    textAlign: 'center',
  },
});
