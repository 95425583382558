import { ITextProps, StyledProps, Text as BaseText } from 'native-base';
import React, { ComponentProps, FC } from 'react';
import { Platform } from 'react-native';
import {
  ExternalToOptions,
  NavigateOptions,
  To,
  useSmartNavigate,
} from '../../utils/useSmartNavigate';

type LinkProps = ComponentProps<typeof BaseText> & {
  bold?: boolean;
  to: To;
  toOptions?: NavigateOptions;
  externalToOptions?: ExternalToOptions;
};

export function Link({ to, toOptions, externalToOptions, ...props }: LinkProps): JSX.Element {
  const smartNavigate = useSmartNavigate();

  // This can be overridden by providing an onPress prop.
  const onPress = (): void => {
    smartNavigate(to, toOptions, externalToOptions);
  };

  // Without any href, it won't properly render as an anchor and have enter keypress click work.
  const linkProps =
    Platform.OS === 'web'
      ? {
          href: '',
          tabIndex: 0,
        }
      : {};

  return <BaseText onPress={onPress} role="link" {...linkProps} {...props} />;
}

const linkFactory =
  (textStyle: Partial<ITextProps>): FC<LinkProps> =>
  ({ children, bold, ...props }) => {
    const cursorStyles = Platform.OS === 'web' ? { cursor: 'pointer' } : {};

    return (
      <Link {...textStyle} {...cursorStyles} bold={bold} {...props}>
        {children}
      </Link>
    );
  };

const generalLinkStyles: StyledProps = {
  color: 'primary.600',
};

// LINKS
export const para = linkFactory({
  ...generalLinkStyles,
  variant: 'paragraph',
});

export const paraSmall = linkFactory({
  ...generalLinkStyles,
  variant: 'paragraphSmall',
});

export const paraLarge = linkFactory({
  ...generalLinkStyles,
  variant: 'paragraphLarge',
});

export const caption = linkFactory({
  ...generalLinkStyles,
  variant: 'caption',
});
