import React, { ReactNode } from 'react';
import { Button, Heading, Layout, SimpleModal } from '../../../components/core';
import { PageContent } from '../../../components/page';
import { getStylesheet } from '../../../styles';

export type StartModalProps = {
  onClosePress: () => void;
  onNextPress: () => void;
};

type CoachingAssessmentStartModalProps = StartModalProps & {
  children: ReactNode;
  title: string;
};

export const CoachingAssessmentStartModal = ({
  children,
  title,
  onClosePress,
  onNextPress,
}: CoachingAssessmentStartModalProps): JSX.Element => {
  const nextButton = (
    <Button.primaryMedium
      maxWidth="500px"
      width="100%"
      onPress={onNextPress}
      testID="coaching-assessment-start-modal-next"
    >
      Next
    </Button.primaryMedium>
  );

  const headerId = 'coaching-assessment-start-modal-header';

  return (
    <SimpleModal
      {...styles.modalContent}
      footer={nextButton}
      footerStyles={styles.footer}
      headerId={headerId}
      onClose={onClosePress}
      size="full"
      testID="coaching-assessment-start-modal"
    >
      <PageContent
        paddingBottom={0}
        pageSize="medium"
        testID="coaching-assessment-start-modal-content"
      >
        <Layout.VStack space={4}>
          <Layout.Center {...styles.header}>
            <Heading.h5 id={headerId}>{title}</Heading.h5>
          </Layout.Center>

          <Layout.Box {...styles.slide}>{children}</Layout.Box>
        </Layout.VStack>
      </PageContent>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  modalContent: {
    borderRadius: 0,
    height: '100%',
    maxHeight: '100%',
    width: '100%',
  },

  header: {
    paddingTop: 1,
  },

  slide: {
    padding: 4,
  },

  footer: {
    backgroundColor: 'inherit',
    borderTopWidth: 0,
    flexDirection: 'column',
    alignContent: 'center',
    paddingLeft: 8,
    paddingRight: 8,
  },
});
