import { JSX } from 'react';
import { Button, Heading, Layout, Skeleton, Text } from '../../components/core';
import { IconAlert } from '../../components/icons/IconAlert';
import { IconBuilding } from '../../components/icons/IconBuilding';
import { IconHeartHandShake } from '../../components/icons/IconHeartHandShake';
import { IconLifeBuoy } from '../../components/icons/IconLifeBuoy';
import { PageContent, PageFooter, PageHeader } from '../../components/page';
import { ExternalAppointmentWidget } from '../../content/appointment/components/ExternalAppointmentWidget';
import { JoinAppointmentWidget } from '../../content/appointment/components/JoinAppointmentWidget';
import { MobilePromotionBanner } from '../../content/promotion/components/MobilePromotionBanner';
import { View } from '../../content/view/components/View';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { CoachingDashboardCard } from './components/CoachingDashboardCard';
import { FindCareDashboardCard } from './components/FindCareDashboardCard';
import { ImageSelfCareHand } from './components/ImageSelfCareHand';
import { QuickActionCard } from './components/QuickActionCard';
import { StudentReferralCard } from './components/StudentReferralCard';
import { TogetherAll } from './components/TogetherAll';
import { UnreadExternalMessagesWidget } from './components/UnreadExternalMessagesWidget';
import { useHomePage } from './hooks/useHomePage';

export const HomePage = (): JSX.Element => {
  const navigate = useNavigate();

  const { data: homePageData, loading } = useHomePage();

  if (loading) {
    return (
      <PageContent testID="home-page">
        <Skeleton {...styles.pageSkeleton} />
      </PageContent>
    );
  }

  const { orgName, recommendationStatus, therapyCardRoute, show, userGreeting } = homePageData;

  const onSelfCareClick = (): void => {
    navigate(getRoute('selfCareHome', {}));
  };

  return (
    <PageContent testID="home-page">
      <MobilePromotionBanner />

      <PageHeader title={`Welcome${userGreeting}`} />

      <Layout.Box {...styles.topWrapper}>
        <JoinAppointmentWidget />

        <UnreadExternalMessagesWidget />
        <ExternalAppointmentWidget />

        {show.findCare && <FindCareDashboardCard recommendationStatus={recommendationStatus} />}

        {show.coaching && <CoachingDashboardCard />}

        <Layout.Flex {...styles.quickActionWrapper} aria-label="Quick actions" role="region">
          <Layout.Flex {...styles.quickActions}>
            {show.therapy && (
              <QuickActionCard
                icon={<IconHeartHandShake size={8} color="primary.600" aria-hidden />}
                title="Mantra Therapy"
                caption="Access your clinical care"
                linkTo={getRoute(therapyCardRoute, {})}
              />
            )}
            {show.onDemand && (
              <QuickActionCard
                icon={<IconLifeBuoy size={8} color="primary.600" aria-hidden />}
                title="On-Demand Emotional Support"
                caption="Get support 24/7"
                linkTo={getRoute('onDemandHome', {})}
              />
            )}
            {show.connectNow && (
              <QuickActionCard
                icon={<IconLifeBuoy size={8} color="primary.600" aria-hidden />}
                title="ConnectNow"
                caption="Get support 24/7"
                linkTo={getRoute('connectNowHome', {})}
              />
            )}
          </Layout.Flex>

          <Layout.Flex {...styles.quickActions}>
            {show.campusResources && (
              <QuickActionCard
                icon={<IconBuilding size={8} color="primary.600" aria-hidden />}
                title="Campus Resources"
                caption="Find support on campus"
                linkTo={getRoute('campusResources', {})}
              />
            )}

            <QuickActionCard
              icon={<IconAlert size={8} color="red.600" aria-hidden />}
              title="Crisis Resources"
              caption="Emergency Resources"
              linkTo={getRoute('crisisResources', {})}
            />
          </Layout.Flex>
        </Layout.Flex>

        {show.studentReferral && <StudentReferralCard orgName={orgName} />}

        <Layout.Shift
          {...styles.selfCareBox}
          aria-labelledby="self-care-card-header"
          alignNonMobile="center"
          role="region"
        >
          <ImageSelfCareHand aria-hidden size="160px" />

          <Layout.VStack {...styles.selfCareTopStack} space={8}>
            <Layout.VStack space={3}>
              <Heading.h4 color="lightText" id="self-care-card-header">
                Self Care
              </Heading.h4>

              <Heading.h3 color="lightText">
                Get the tools to deal with whatever comes your way.
              </Heading.h3>

              <Text.para color="lightText">
                Quick, practical, evidenced-based skills to help you feel better and handle any
                situation.
              </Text.para>
            </Layout.VStack>

            <Button.primaryMedium
              {...styles.browseButton}
              testID="button-home-go-to-self-care"
              onPress={onSelfCareClick}
            >
              Browse Skills
            </Button.primaryMedium>
          </Layout.VStack>
        </Layout.Shift>

        {show.togetherAll && <TogetherAll orgName={orgName} />}

        <Layout.VStack {...styles.learn}>
          <View isCollection hideHeader />
        </Layout.VStack>
      </Layout.Box>

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  pageSkeleton: {
    height: '100vh',
  },

  topWrapper: {
    gap: 4,
  },

  quickActionWrapper: {
    flex: 1,
    gap: 4,
  },

  selfCareBox: {
    borderRadius: '8px',
    backgroundColor: 'secondary.900',
    gap: 8,
    padding: 8,
  },

  selfCareTopStack: {
    flex: 1,
  },

  browseButton: {
    maxWidth: '145px',
    _web: {
      maxWidth: '133px',
    },
  },

  quickActions: {
    flexDirection: 'row',
    flexGrow: 1,
    flexWrap: 'wrap',
    gap: 2,
    justifyContent: 'space-between',
    width: '100%',
  },

  learn: {
    justifyContent: 'center',
  },
});
