import { IPressableProps, Pressable as BasePressable } from 'native-base';
import { forwardRef } from 'react';
import { Platform } from 'react-native';

export type PressableProps = IPressableProps & {
  testID: string;
};

export const Pressable = forwardRef(({ children, ...props }: PressableProps, ref): JSX.Element => {
  // Without any href, it won't properly render as an anchor and have enter keypress click work.
  const linkProps =
    Platform.OS === 'web' && props.role === 'link'
      ? {
          href: '',
          tabIndex: 0,
        }
      : {};

  return (
    <BasePressable role="button" {...linkProps} {...props} ref={ref}>
      {children}
    </BasePressable>
  );
});
